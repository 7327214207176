// TODO: нужен рефакторинг
export default {
  computed: {
    balance() {
      const balance = parseInt(this.userData?.balance?.replace(/(\s|\u00A0|&nbsp;)+/g, ''));
      if (isNaN(balance)) {
        return 0;
      }
      return balance;
    },
    sale() {
      // у wl не должно быть скидок
      if (
        Number(this.currencyCalc.id) === 1 &&
        Number(this.currency.id === 1) &&
        this.isAuth &&
        !this.whitelabel &&
        !this.isLoadUserData
      ) {
        const sale = Math.floor(this.oldPrice * 0.10);
        if (this.userData?.balance_virtual) {
          const bonus =
            this.userData?.balance_virtual > 100000
              ? 100000
              : this.userData?.balance_virtual;
          return sale <= bonus ? sale : bonus;
        } else {
          return 0;
        }
      }
      return 0;
    },
    salePrice() {
      return this.oldPrice - this.sale;
    },
    oldPrice() {
      const defaultPrice = this.priceNight ? this.priceNight.value : '';
      return this.getObjectPrice?.price || defaultPrice;
    }
  }
}
